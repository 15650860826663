<template>
    <div class="box">
        <div v-if="isverify">
            <div v-if="login == 2"></div>
            <div v-else-if='login' :style="style">

                <div style="margin-top: 25%;color: #FFFFFF;margin-bottom:2%;font-size: 14px;">使用智工邦业务管理平台之前，请先验证您的账号</div>
                <div style="color: aliceblue;">
                    （团队管理者添加的手机号码）
                </div>
                <div style="padding:0 5%;margin-top: 15%;">
                    <div style="text-align: left;font-size: 14px;color: aliceblue;width: 95%;margin: 0 auto;">
                        手机号码
                    </div>
                    <div class="box" style="display: flex;width: 100%;justify-content: center;height: 25px;align-items: center;border: 1px solid white;border-radius: 20px;padding: 5px;width: 90%;margin:5% auto;">
                        <input @blur="fixScroll" type="tel" placeholder="请输入手机号" v-model="mobile" @input="mobile=mobile.replace(/[^\d]/g,'')" maxlength="11" style="flex: 1;height: 95%;outline: none;border: 0;color: aliceblue;background-color: transparent;margin-left: 12px;box-sizing: border-box;text-align: left;">
                        <div v-if="time_status" style="color: aliceblue;margin-right: 12px;box-sizing: border-box;" @click="toyzm">
                            获取验证码
                        </div>
                        <div v-else style="color: aliceblue;margin-right: 12px;">
                            {{num}}
                        </div>
                    </div>

                </div>
                <div style="padding:0 5%;margin-top: 5%;">
                    <div style="text-align: left;font-size: 14px;color: aliceblue;width: 95%;margin: 0 auto;">
                        验证码
                    </div>

                    <div style="display: flex;width: 100%;justify-content: center;height: 25px;align-items: center;border: 1px solid white;border-radius: 20px;padding: 5px ;width: 90%;margin:5% auto;">
                        <input @blur="fixScroll" placeholder="请输入验证码" v-model="code" type="tel" maxlength="6" @input="code=code.replace(/[^\d]/g,'')" style="flex: 1;height: 95%;outline: none;border: 0;color: aliceblue;background-color: transparent;margin-left: 12px;box-sizing: border-box;text-align: left;">
                    </div>
                </div>
                <div>
                    <div @click="tologin" style="padding: 5px 10px;width: 100px;margin: 0 auto;margin-top: 16%;background:rgba(255,255,255,.8);
			height: 30px;line-height: 30px;border-radius: 20px;color: white;text-align: center;color: #42b983;
			font-size: 15px;font-weight: bold;
			">
                        确认
                    </div>
                </div>
            </div>
            <div v-else class="error_box">
                <van-icon name="clear" color="#4C94F6" size="40px" />

                <div class="error_text">
                    {{error}}

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Dialog, Icon, Toast } from 'vant';

export default {
    name: "Login",
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Icon.name]: Icon,
    },
    data() {
        return {
            num: 60,
            time_status: true,
            mobile: "",
            code: "",
            style: {
                width: '100%',
                height: '100vh',
                'box-sizing': 'border-box',
                'overflow': 'hidden',
                'font-size': '13px',
                'text-align': 'center',
                'background': 'url(' + require('../../public/bg.png') + ")"
            },
            wxtoken: "",
            login: 2,
            error: "",
            isverify: true
        }
    },
    mounted() {
        this.$store.commit("hideTabbar");
        this.onload()

    },
    methods: {
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        onload() {
            this.Request('account/verify', 'post', { code: this.getCode(), member_id: this.getQuery('member_id') || localStorage.getItem('member_id') }, false).then(res => {
                // alert(res.data.action)
                if (res.status == 0) {
                    if (res.data.action != 'mobile_verify') {
                        this.isverify = false
                        Dialog.alert({
                            message: '点击确认即可绑定成功',
                        }).then(() => {
                            // on close
                            this.Request('account/verify', 'put', { uuid_verify_token: res.data.uuid_verify_token, confirm: 'confirm' }, false).then(res2 => {
                                if (res2.data.bind_success) {
                                    this.$router.replace({
                                        path: '/successLogin'
                                    })
                                } else {
                                    this.$toast(res.msg);
                                }
                            })
                        });
                    } else {
                        this.wxtoken = res.data.uuid_verify_token
                        this.login = 1
                        // alert(this.wxtoken)
                    }
                } else {
                    this.login = 0
                    this.error = res.msg
                }


            })
        },
        fixScroll() {
            window.scrollTo(0, 0);
        },
        toyzm() {
            if (this.getTel()) {
                this.Request('/WATS/send_sms', 'get', {
                    mobile: this.mobile,
                    option: 'verify'
                }, false).then(res => {
                    if (res.status == 0) {
                        this.$toast(res.msg);
                        this.time_status = false

                        var time = setInterval(() => {
                            if (this.num <= 0) {
                                clearInterval(time)
                                this.time_status = true
                                this.num = 60
                            } else {
                                this.num--;
                            }
                        }, 1000)
                    } else {
                        this.$toast(res.msg);
                    }
                })
            }
        },
        getTel() {
            if (!!this.mobile == false) {
                Toast('请输入手机号码')
                return false
            }
            if (!(/^[1]([3-9])[0-9]{9}$/.test(this.mobile))) {
                Toast('请输入正确的手机号码')
                return false
            }
            return true
        },
        getCode() {
            let qrCodeUrl = window.location.href;
            let params = qrCodeUrl.split("?")
            let params1 = params[1].split("&")
            let code = params1[0].split("=")[1]
            return code
        },
        tologin() {
            if (this.getTel()) {
                if (!!this.code == false) {
                    Toast('请输入验证码')
                    return
                }
                this.Request('account/verify', 'put', {
                    mobile: this.mobile,
                    sms_code: this.code,
                    uuid_verify_token: this.wxtoken
                }, false).then(res => {
                    if (res.status == 0) {
                        if (res.data.action != 'mobile_verify') {
                            Dialog.alert({
                                message: '点击确认即可绑定成功',
                            }).then(() => {

                                this.Request('account/verify', 'put', { uuid_verify_token: res.data.uuid_verify_token, confirm: 'confirm' }, false).then(res2 => {
                                    if (res.status == 0) {
                                        if (res2.data.bind_success) {
                                            this.$router.replace({
                                                path: '/successLogin'
                                            })
                                        }

                                    } else {
                                        this.$toast(res.msg);
                                    }
                                })
                            });
                        } else {
                            if (res.data.bind_success) {
                                this.$router.replace({
                                    path: '/successLogin'
                                })
                            }
                        }
                    } else {
                        this.$toast(res.msg);
                    }
                })
            }
        }
    }
}
</script>

<style>
.box input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgba(255, 255, 255, 0.7);
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(255, 255, 255, 0.7);
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(255, 255, 255, 0.7);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(255, 255, 255, 0.7);
}
.error_box {
    text-align: center;

    padding: 30% 10% 0;
}
.error_text {
    font-size: 30px;
}
</style>
